<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <div v-if="exam">
      <b-row>
        <b-col cols="12">
          <b-card>

            <h4>اطلاعات آزمون</h4>

            <!-- Media -->
            <b-media class="my-2">
              <template>
                <b-avatar
                    ref="previewEl"
                    :src="base64ImageSrc"
                    size="300px"
                    rounded
                />
              </template>
              <div class="d-flex flex-wrap mt-1">
                <b-button
                    variant="primary"
                    @click="$refs.refInputEl.click()"
                >
                  <input
                      ref="refInputEl"
                      type="file"
                      class="d-none"
                      @input="makeBase64Pic"
                  >
                  <span class="d-none d-sm-inline">+ عکس</span>
                  <feather-icon
                      icon="EditIcon"
                      class="d-inline d-sm-none"
                  />
                </b-button>
<!--                <b-button-->
<!--                    variant="outline-secondary"-->
<!--                    class="ml-1"-->
<!--                    @click="deleteProductPic()"-->
<!--                >-->
<!--                  <span class="d-none d-sm-inline">حذف</span>-->
<!--                  <feather-icon-->
<!--                      icon="TrashIcon"-->
<!--                      class="d-inline d-sm-none"-->
<!--                  />-->
<!--                </b-button>-->
              </div>
            </b-media>
            <div>
              <b-form>
                <b-row>
                  <!-- Field:  Title -->
                  <b-col
                      cols="12"
                      md="6"
                  >
                    <b-form-group
                        label="عنوان"
                        label-for="Title"
                    >
                      <b-form-input
                          id="Title"
                          v-model="exam.title"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: productCategoryId -->
                  <b-col v-if="categories !== null"
                         cols="12"
                         md="6"
                         class="mb-2"
                  >
                    <b-form-group
                        label="دسته بندی"
                        label-for="productCategoryId"
                    >
                      <v-select
                          v-model="exam.categoryId"
                          :options="categories"
                          label="name"
                          :reduce="name => name.productCategoryId"
                          :clearable="false"
                          input-id="productCategoryId"
                      />
                    </b-form-group>
                    <span class="text-danger">توجه داشته باشید که هر دسته بندی فقط یک الگوی آزمون میتواند داشته باشد.</span>
                  </b-col>

                  <!-- Field:  price -->
                  <b-col
                      cols="12"
                      md="4"
                  >
                    <b-form-group
                        label="قیمت (تومان)"
                        label-for="price"
                    >
                      <b-form-input
                          id="price"
                          v-model="exam.price"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: isAvailable -->
                  <b-col
                      cols="12"
                      md="4"
                  >
                    <b-form-group
                        label="وضعیت"
                        label-for="isAvailable"
                    >
                      <v-select
                          disabled
                          v-model="exam.available"
                          :options="isAvailableOptions"
                          :reduce="val => val.value"
                          :clearable="false"
                          input-id="isAvailable"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: totalQuestionsCount -->
                  <b-col
                      cols="4"
                  >
                    <b-form-group
                        label="تعداد کل پرسش‌ ها"
                        label-for="totalQuestionsCount"
                    >
                      <b-form-input
                          type="number"
                          id="totalQuestionsCount"
                          v-model="exam.totalQuestionsCount"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: easyLevelQuestionsCount -->
                  <b-col
                      cols="4"
                  >
                    <b-form-group
                        label="تعداد پرسش‌ های آسان"
                        label-for="easyLevelQuestionsCount"
                    >
                      <b-form-input
                          type="number"
                          id="easyLevelQuestionsCount"
                          v-model="exam.easyLevelQuestionsCount"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: mediumLevelQuestionsCount -->
                  <b-col
                      cols="4"
                  >
                    <b-form-group
                        label="تعداد پرسش‌ های متوسط"
                        label-for="mediumLevelQuestionsCount"
                    >
                      <b-form-input
                          type="number"
                          id="mediumLevelQuestionsCount"
                          v-model="exam.mediumLevelQuestionsCount"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: hardLevelQuestionsCount -->
                  <b-col
                      cols="4"
                  >
                    <b-form-group
                        label="تعداد پرسش‌ های سخت"
                        label-for="hardLevelQuestionsCount"
                    >
                      <b-form-input
                          type="number"
                          id="hardLevelQuestionsCount"
                          v-model="exam.hardLevelQuestionsCount"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field:  description -->
                  <b-col cols="12">
                    <b-form-group
                        class="mb-2"
                        label="توضیحات"
                        label-for="product-content"
                    >
                      <ckEditorMain ref="editorContent" @getEditorContent="setEditorContent"
                                    :defaultContent="exam.description"></ckEditorMain>
                    </b-form-group>
                  </b-col>

                </b-row>
              </b-form>

              <b-button
                  variant="primary"
                  class="mt-1 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="updateExam"
              >
                ثبت
              </b-button>
            </div>
          </b-card>

          <b-card>

            <h4>وضعیت آزمون</h4>
            <b-button
                variant="success"
                class="mt-1 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="setAvailable"
            >
              موجود شود
            </b-button>
            <b-button
                variant="danger"
                class="mt-1 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="setUnavailable"
            >
              ناموجود شود
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
  BTab,
  BTabs,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BMedia,
  BAvatar,
  BOverlay
} from 'bootstrap-vue'
import vSelect from "vue-select";
import NewCategoryProperty from "@/views/apps/e-commerce/e-commerce-add/NewCategoryProperty";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {
  ProductCategoryGetAllCourseCategoriesRequest,
  ProductCategoryGetCourseCategoriesForExamRequest
} from "@/libs/Api/Product";
import {
  ExamCreateRequest,
  ExamDeleteRequest,
  ExamGetRequest,
  ExamSetExamAvailableRequest, ExamSetExamUnavailableRequest,
  ExamUpdateRequest
} from "@/libs/Api/Exam";
import ckEditorMain from "@/views/components/ckEditorMain.vue";
import Helper from "@/libs/Helper";

export default {
  title: "ویرایش آزمون - پنل ادمین مکس",
  name: "ExamEdit",
  components: {
    NewCategoryProperty,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BTab,
    BTabs,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BMedia,
    BAvatar,
    BOverlay,
    vSelect,
    ckEditorMain,
  },
  data() {
    return {
      overlay: false,
      exam:null,
      isAvailableOptions: [
        {label: 'موجود', value: true},
        {label: 'ناموجود', value: false}
      ],
      base64ImageSrc: null,
      categories: null,
    }
  },
  async created() {
    await this.getExam();
    await this.getAllCourseCategories();
  },
  methods: {
    async getExam() {
      let _this = this;
      _this.overlay = true;

      let data = {
        id:_this.$route.params.id
      }

      let examGetRequest = new ExamGetRequest(_this);
      examGetRequest.setParams(data);
      await examGetRequest.fetch(function (content) {
        _this.overlay = false;
        _this.exam = content.data;
        _this.base64ImageSrc = Helper.baseUrl+_this.exam.imageUrl;
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
    async updateExam() {
      let _this = this;
      _this.overlay = true;

      let examUpdateRequest = new ExamUpdateRequest(_this);
      examUpdateRequest.setParams(_this.exam);
      await examUpdateRequest.fetch(function (content) {
        _this.overlay = false;
        if (content.isSuccess) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات موفق`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `عملیات انجام شد.`,
            },
          })
          _this.getExam();
        } else {
          return _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات ناموفق`,
              variant: 'danger',
              text: content.errorMessage,
            },
          })
        }
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
    async getAllCourseCategories() {
      let _this = this;
      _this.overlay = true;
      let defaultGetData = {
        pageNumber: 0,
        count: 0,
      }

      let productCategoryGetAllProductCategoriesRequest = new ProductCategoryGetCourseCategoriesForExamRequest(_this);
      productCategoryGetAllProductCategoriesRequest.setParams(defaultGetData);
      await productCategoryGetAllProductCategoriesRequest.fetch(function (content) {
        _this.overlay = false;
        content.productCategories.push(
            {
              productCategoryId: _this.exam.categoryId,
              name: _this.exam.categoryName
            }
        )
        _this.categories = content.productCategories;
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
    async setAvailable() {
      let _this = this;
      _this.overlay = true;
      let data = {
        id:_this.$route.params.id
      }

      let examSetExamAvailableRequest = new ExamSetExamAvailableRequest(_this);
      examSetExamAvailableRequest.setParams(data);
      await examSetExamAvailableRequest.fetch(function (content) {
        _this.overlay = false;
        if (content.isSuccess){
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات موفق`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        }else {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات موفق`,
              variant: 'danger',
              text: content.errorMessage,
            },
          })
        }
        _this.getExam()
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
    async setUnavailable() {
      let _this = this;
      _this.overlay = true;
      let data = {
        id:_this.$route.params.id
      }

      let examSetExamUnavailableRequest = new ExamSetExamUnavailableRequest(_this);
      examSetExamUnavailableRequest.setParams(data);
      await examSetExamUnavailableRequest.fetch(function (content) {
        _this.overlay = false;
        if (content.isSuccess){
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات موفق`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        }else {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات موفق`,
              variant: 'danger',
              text: content.errorMessage,
            },
          })
        }
        _this.getExam()
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
    async readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = () => {
          resolve(fr.result.split(",")[1]);
        }
        fr.readAsDataURL(file);
      });
    },
    async makeBase64Pic(e) {
      const _this = this;
      let file = e.target.files[0]
      _this.base64ImageSrc = URL.createObjectURL(file)
      const result = await _this.readAsDataURL(file);
      _this.exam.imageFile = result;
    },
    deleteProductPic() {
      this.exam.imageFile = null;
      this.base64ImageSrc = null;
    },
    setEditorContent(content) {
      this.exam.description = content;
    },
  },
}
</script>

<style scoped>

</style>